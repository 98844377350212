@font-face {
  font-family: 'Pixeboy';
  src: url('../public/font/Pixeboy.ttf') format('truetype');
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Pixeboy'
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  background: #000000;
  font-family: monospace;
  color: white;
}

#crosshair {
  position: absolute;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.crosshair-horizontal,
.crosshair-vertical {
  position: absolute;
  background-color: white;
}

.crosshair-horizontal {
  width: 20px;
  height: 2px;
}

.crosshair-vertical {
  width: 2px;
  height: 20px;
}

.crosshair-horizontal::before,
.crosshair-vertical::before {
  content: '';
  position: absolute;
  background-color: white;
  width: 10px;
  height: 2px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.enter-screen{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.enter-screen h1{
    font-size: 8rem;
}

.enter-screen button {
  margin: 5rem;
  width: 30%;
  padding: 0.5rem 1rem;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-size:4rem;
  text-align: center;
  letter-spacing: 1px;
  outline: none;
  cursor: pointer;
  transition: border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

.enter-screen button:focus,
.enter-screen button:hover {
  border-color: lightblue;
  background-color: rgba(255, 255, 255, 0.2);
  color: lightblue;
}

.start-screen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-image: url('../public/img/startScreen.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.start-container1 {
  margin: 0;
  padding: 0;
  height: 71%;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 8px solid white;
  padding: 1rem;
  background-color: rgba(0,0,0, 0.2);
}

.start-container2 {
  margin: 0;
  padding: 0;
  height: 71%;
  width: 50%;
  display: flex;
  gap: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.keybinds-container {
  margin: 0;
  padding: 0;
  height: 70%;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 8px solid white;
  padding: 1rem;
  background-color: rgba(0,0,0, 0.2);
}

.keybinds {
  margin-top: 1%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50%;
}

.keybinds-container h2 {
  font-size: 4rem;
}

.keybinds h3 {
  font-size: 3rem;
}

.controles{
  margin: 0;
  padding: 0;
  height: 90%;
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.movimiento{
  margin: 0;
  padding: 0;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;  
  justify-content: start;
  align-items: center;
}

.w-movimiento{
  border: 8px solid white;
  height: 60%;
  width: 30%;
  display: flex;
  flex-direction: column;  
  justify-content: center;
  align-items: center;
  font-size: 3rem;
}

.asd-movimiento{
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  display: flex;
  flex-direction: row;  
  justify-content: space-around;
  align-items: center;
}

.a-movimiento, 
.s-movimiento,
.d-movimiento{
  border: 8px solid white;
  height: 75%;
  width: 30%;
  display: flex;
  flex-direction: column;  
  justify-content: center;
  align-items: center;
  font-size: 3rem;
}

.disparo{
  margin: 0;
  padding-left: 20px;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;  
  justify-content: center;
  align-items: center;
}

.un-rectangulo{
  border: 8px solid white;
  height: 70%;
  width: 30%;
  display: flex;
  flex-direction: column;  
  justify-content: left;
  align-items: start;

}

.otro-rectangulo{
  border: 4px solid white;
  height: 50%;
  width: 55%;
  background-color: white;
}

.linea{
  border: 4px solid white;
  height: 1%;
  width: 100%;
  background-color: white;
}


.instructions {
  margin: 0;
  padding: 0;
  height: 70%;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 8px solid white;
  padding: 1rem;
  background-color: rgba(0,0,0, 0.2);
}

.instructions h2 {
  font-size: 4rem;
}

.instructions p {
  font-size: 2.5rem;
}

.start-screen h1{
  font-size: 6rem;
  margin-top: 50px;
  color: rgb(255, 255, 255);
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.user-info h2 {
  font-size: 3rem;
  margin-top: 8rem;
  color: rgb(255, 255, 255);
}

.user-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.user-form label,
.user-form input,
.user-form button {
  width: 100%;
}

.user-form input {
  width: 100%;
  padding: 0.5rem;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-size:4rem;
  text-align: center;
  letter-spacing: 1px;
  outline: none;
  caret-color: white;
}


.user-form input:focus {
  border-color: lightblue;
}

.user-form input::placeholder {
  color: rgb(232, 232, 232);
}

.user-form button {
  margin-bottom: 5rem;
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-size:4rem;
  text-align: center;
  letter-spacing: 1px;
  outline: none;
  cursor: pointer;
  transition: border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

.user-form button:focus,
.user-form button:hover {
  border-color: lightblue;
  background-color: rgba(255, 255, 255, 0.2);
  color: lightblue;
}

.result-screen {
  width: 100%;
  height: 100%;
  background-image: url('../public/img/endScreen.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
}

.result-1player{
  margin: 40px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
  border: 8px solid white;
  padding: 3rem;
  background-color: rgba(0,0,0, 0.5);
}

.result-1player h1{
  font-size:3.5rem;
  margin: 6px;
}

.result-1player h2{
  font-size:3.5rem;
  margin-top: 70px;
}

.result-1player p{
  font-size:2.5rem;
  margin: 10px;
}

.restart button {
  margin-top: 8rem;
  margin-bottom: 1rem;
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-size:3rem;
  text-align: center;
  letter-spacing: 1px;
  cursor: pointer;
  transition: border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

.restart button:focus,
.restart button:hover {
  border-color: lightblue;
  background-color: rgba(255, 255, 255, 0.1);
  color: lightblue;
}

.result-best3players{
  margin: 40px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
  border: 8px solid white;
  padding: 3rem;
  align-content: space-between;
  background-color: rgba(0,0,0, 0.5);
}

.result-best3players h1{
  font-size:3.5rem;
  margin: 10px;
}

.result-best3players h2{
  font-size:3rem;
  margin: 5px;
}

.result-best3players p{
  font-size:2rem;
  margin: 10px;
  margin-bottom: 15px;
}

.top-1{
  border: 4px solid rgb(212,175,55);
  padding: 1rem;
}

.top-2{
  border: 4px solid rgb(192,192,192);
  padding: 1rem;
}

.top-3{
  border: 4px solid rgb(203, 109, 81);
  padding: 1rem;
}